
.Card {
    backdrop-filter: blur(4px);
    border: 1px solid rgba(153, 153, 153, 0.497);
    border-radius: 20px;
    padding: 20px;
    margin-bottom: 25px;
    width: 500px;
    text-align: center;
    background: linear-gradient(145deg, #1c1e24, #16181d);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    transition: transform 0.3s ease;
    .soundButton {
        background-image: url('./muted_sound.png');
    }
}

.Card:hover {
    transform: translateY(-10px);
    box-shadow: 0 12px 20px rgba(0, 0, 0, 0.2);
    .soundButton {
        background-image: url('./sound.png');
    }
}

.Card-header {
    margin-bottom: 20px;
    display: flex;
    justify-content:center ;
    width: 500px;
}

.Card-header-content {
    width: 700px;
    padding-right: 50px;
}

.Header-edit-button {
    padding-top: 20px;
    width: 100px;
}

.title {
    font-family: "Pixel";
    font-size: 25px;
    font-weight: bold;
    margin-bottom: 10px;
}

.author {
    font-family: "Pixel";
    font-size: 18px;
    color: #a0a5ab;
    margin-bottom: 15px;
}

.description {
    font-family: "Pixel";
    font-size: 22px;
    text-align: center;
    margin-top: 20px;
    margin-bottom: 25px;
}

.likeButton, .betButton {
    border-radius: 8px;
    padding: 10px 20px;
    font-size: 16px;
    background-color: #2b2a2a;
    border: 1px solid transparent;
    margin: 5px;
    transition: all 0.3s ease;
    align-self: stretch;
    margin-top: 20px;
    font-family: "Pixel";
}

.likeButton:hover, .betButton:hover {
    background-color: #4a4e57;
    color: #ffffff;
    border-color: #646cff;
    transform: scale(1.05);
}

.likeButton:active, .betButton:active{
    background-color: #bfc216;
    box-shadow: 0 5px #666;
    transform: translateY(4px);
  }

.moreButton {
    width: 30px;
    align-self:left;
    margin-bottom: 20px;
}

.moreButton:hover {
    transform: scale(1.2);
}

p {
    font-family: "Pixel";
    font-size: 20px;
    margin-bottom: 10px;
}

.tag-featured {
    font-family: "Pixel";
    font-size: 18px;
    color: #dad38d;
    margin-bottom: 15px;
}

.tag-saved {
    font-family: "Pixel";
    font-size: 18px;
    color: #cfcbcb;
    margin-bottom: 15px;
    font-style: italic;
}

.posts {
    font-family: "Pixel";
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
}

.profile-title {
    display: center;
    font-family: "Pixel";
    font-size: 30px;
    font-weight: bold;
    margin-bottom: 30px;
    margin-left: 17px;
}

.Card {animation: slideIn 1.5s ease-in-out;}
@keyframes slideIn {
    0% {
        transform: translateX(-25%);
        opacity: 0;
    }
    50% {
        transform: translateX(0%);
        opacity: 1;
    }
}

.editing-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.soundButton {
    width: 30px;
    align-self: right;
    margin-bottom: 20px;
}

.soundButton:hover {
    transform: scale(1.2);
}

.link {
    text-decoration: none;
}
