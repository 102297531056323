@font-face {
  font-family: "Pixel";
  src: url(fonts/JosefinSans.ttf);
}

.App {
  font-family:Avenir, "Century Gothic", sans-serif;
  font-weight:100; 
  font-style:normal;
  font-size: calc(10px + 2vmin);
  color: white;
  justify-content: center;
  background-color: #121212;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

.header {
  width: 100%;
  height: 100px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #161616;
  color: white;
  text-align: center;
  margin-bottom: 20px;
  padding: 0 10px;
}

h1 {
  padding-left: 40px ;
}

button {
  border-radius: 8px;
  margin-right: 10px;

  border: 1px solid transparent;
  padding: 0.6em 1.2em;
  font-size: 20px;
  font-weight: 500;
  font-family: inherit;
  background-color: #773193;
  color:rgb(255, 251, 251);
  cursor: pointer;
  transition: all 0.5s ease-in-out;
}

button:hover {
  border-color: #ffffff;
  cursor: pointer;
  transform: scale(1.2);
  transition: all 0.5s ease-in-out;
}


.nav_links{
  font-family: "Pixel";
  font-size: 16px;
}

.header h1 {
  font-family: "Pixel";
  font-size: 35px;
}

@media (max-width: 1520px) {
  .header {
    flex-direction: column;
    height: auto;
    padding: 20px 10px;
  }

  .header h1 {
    font-family: "Pixel";
    margin-bottom: 20px;
  }

  .header button {
    font-size: 14px;
    padding: 5px 10px;
    margin: 5px;
  }
}