input {
    margin-top: 10px;
    width: 100%;
    height: 45px;
    border-radius: 8px;
    border: 1px solid transparent;
    font-size: 20px;
    padding-left: 10px;
    font-family: "Pixel";
}

label {
    font-size: 20px;
    font-family: "Pixel";
    font-weight: bold;
}

textarea {
    margin-top: 10px;
    border-radius: 8px;
    width: 100%;
    border: 1px solid transparent;
    font-size: 20px;
}

input[type=submit] {
    width: 100%;
    height: 50px;
    cursor: pointer;
    border-radius: 8px;
    border: 1px solid transparent;
    font-size: 20px;
    font-weight: 500;
    font-family: inherit;
    background-color: #1a1a1a;
    color:white;
    cursor: pointer;
    transition: border-color 0.25s;
    margin-top: 20px;
    font-family: "Pixel";
    font-weight: bold;
  }

input[type=submit]:hover {
    border-color: #646cff;
}

button {
    font-family: "Pixel";
    font-size: 15px;
}

.form-group {
    margin-bottom: 15px;
    font-family: "Pixel";
}

.animation-canvas {
    cursor: crosshair;
}