input {
    margin-top: 10px;
    width: 100%;
    height: 45px;
    border-radius: 8px;
    border: 1px solid transparent;
    font-size: 20px;
    padding-left: 10px;
}

label {
    font-size: 20px;
}

textarea {
    border-radius: 8px;
    width: 100%;
    border: 1px solid transparent;
    font-size: 20px;
    font-family: inherit;
}

input[type=submit] {
    width: 100%;
    height: 50px;
    cursor: pointer;
    border-radius: 8px;
    border: 1px solid transparent;
    font-size: 20px;
    font-weight: 500;
    font-family: inherit;
    background-color: #1a1a1a;
    color:white;
    cursor: pointer;
    transition: border-color 0.25s;
    margin-top: 20px;
  }

input[type=submit]:hover {
    border-color: #646cff;
}

.deleteButton {
    width: 100%;
    height: 50px;
    cursor: pointer;
    border-radius: 8px;
    border: 1px solid transparent;
    font-size: 20px;
    font-weight: 500;
    font-family: inherit;
    background-color: #870404;
    color:white;
    cursor: pointer;
    transition: border-color 0.25s;
    margin-top: 20px;
    margin-bottom: 20px;
}

.backButton {
    width: 100%;
    height: 50px;
    cursor: pointer;
    border-radius: 8px;
    border: 1px solid transparent;
    font-size: 20px;
    font-weight: 500;
    font-family: inherit;
    background-color: #773193;
    color: white;
    cursor: pointer;
    transition: border-color 0.25s;

    margin-bottom: 50px;
}

.comment-box {
    margin-top: 20px;
    border-radius: 8px;
    min-width: 450px;
    border: 1px solid transparent;
    border-color: aliceblue;
    font-size: 20px;
    font-family: inherit;
    padding-left: 10px;
    overflow-wrap: break-word;
    overflow-y: auto;
}
